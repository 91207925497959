/* You can add global styles to this file, and also import other style files */
@import './assets/styles/template';
@font-face {
  font-family: 'SF Pro Rounded';
  font-weight: normal;
  src: url('/assets/styles/fonts/FontsFree-Net-SF-Pro-Rounded-Regular.ttf');
}
@font-face {
  font-family: 'SF Pro Rounded';
  font-weight: bold;
  src: url('/assets/styles/fonts/FontsFree-Net-SF-Pro-Rounded-Bold.ttf');
}

.download-app-modal {
  &_subtitle {
    max-width: 480px;
    @media screen and (max-width: 1200px) {
      font-size: 24px;
      line-height: 28.8px;
    }
    font-size: 32px;
    line-height: 38.4px;
    font-weight: 800;
    color: #1F1D2B;

    &-accent {
      color: #000000;
    }
  }

  &_title {
    max-width: 480px;
    @media screen and (max-width: 1200px) {
      font-weight: 500;
      font-size: 14px;
      line-height: 19.6px;
      text-align: center;
    }
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
    color: #3C3C4399;
  }

  &_img {
    width: 100%;
    max-width: 320px;
    min-height: 120px;

    @media screen and (max-width: 768px) {
      min-height: auto;
      max-height: 100px;
      max-width: 245px;
    }
  }

  &_button {
    @media screen and (min-width: 1200px) {
      display: none !important;
    }
  }
}

.qr-code {
  &-wrapper {
    @media screen and (max-width: 1200px) {
      display: none !important;
    }

    &_arrow-icon {
      right: 20%;
      bottom: 40%;
    }

    .qr-code-info {
      font-size: 16px;
      font-weight: 700;
      line-height: 19.2px;
      text-align: center;
      padding: 16px 40px;
      color: #000000;
      border: 2px dashed #00000033;
      border-radius: 12px;
    }
  }
}
